import styled from 'styled-components';

const P = styled.p`
  color: ${props => props.theme.palette.grey['700']};
  font-size: 18px;
  opacity: inherit;
  transition: inherit;
`;

export default P;
