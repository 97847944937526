import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import P from '../components/styles/P';
import Flex from '../components/styles/Flex';
import H2 from '../components/styles/H2';
import Fade from '../components/styles/Fade';
import Span from '../components/styles/Span';
import Spacer from '../components/styles/Spacer';
import { TextArea, Input } from '../components/inputs';
import SEO from '../components/seo';
import encode from '../utils/encodeURIComponent';
import THEME from '../theme';

const CenteredMaxWidth = styled.div`
  max-width: ${props => `${props.width || 750}px`};
  margin: 0 auto;
`;

const Button = styled.button`
  display: flex;
  color: ${props => props.theme.palette.grey['700']};
  padding: 10px 20px;
  border: 1px solid;
  border-color: ${props => props.theme.palette.grey['700']};
  border-radius: 5px;
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 12px;
  text-transform: uppercase;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
  transition: 0.2s all linear;
  &:hover {
    background-color: ${props => props.theme.palette.grey['700']};
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    transition: 0.2s all ease;
  }
`;

class ContactPage extends React.Component {
  state = {
    name: '',
    email: '',
    message: '',
    hasSubmitError: false,
    isLoading: false,
    isSubmitted: false,
  };

  resetForm = () => {
    this.setState({ name: '', email: '', message: '' });
  };

  handleSubmit = async e => {
    e.preventDefault();
    const { email, name, message } = this.state;
    this.setState({ isLoading: true });

    try {
      const res = await fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': 'contact', email, name, message }),
      });

      if (res.error || !res.ok) {
        this.setState({ isLoading: false, hasSubmitError: true });
      } else {
        this.setState({ isLoading: false, isSubmitted: true });
        this.resetForm();
      }
    } catch (error) {
      this.setState({ isLoading: false, hasSubmitError: true });
    }

    setTimeout(() => {
      this.setState({ isSubmitted: false, hasSubmitError: false });
    }, 2500);
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const {
      name,
      email,
      message,
      isLoading,
      hasSubmitError,
      isSubmitted,
    } = this.state;
    return (
      <Layout>
        <SEO title="Contact us" />
        <Spacer size={60} md={120} />
        <CenteredMaxWidth style={{ padding: '0 40px' }}>
          <H2>We'd love to hear from you</H2>
          <Spacer size={20} md={0} />
          <P>
            The best way for us to get to know each other is a free consultation.
            Reach out to us, and we'll schedule a quick 15-minute call to
            determine how to begin this adventure together. You can also just
            say hey, ask a question or tell us a joke. We are friendly folks.
          </P>
          <Spacer size={40} />
          <form
            name="contact"
            method="POST"
            onSubmit={this.handleSubmit}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            {/* eslint-disable-next-line */}
            <input ref="bot-field" type="hidden" name="bot-field" />
            <input
              ref="form-name" // eslint-disable-line
              type="hidden"
              name="form-name"
              value="contact"
            />
            <Input
              label="name"
              id="name"
              required
              onChange={this.handleChange}
              value={name}
            />
            <Input
              label="email"
              id="email"
              required
              type="email"
              onChange={this.handleChange}
              value={email}
            />
            <TextArea
              label="message"
              id="message"
              required
              onChange={this.handleChange}
              value={message}
            />
            <Spacer size={20} />
            <Flex>
              <Button margin="0" disabled={isLoading}>
                Submit
              </Button>
              <Fade in={isSubmitted}>
                <Span
                  color={THEME.palette.primary.dark}
                  style={{ paddingLeft: '16px' }}
                >
                  Got it! Talk soon <span aria-label="peace sign" role="img">✌️</span>
                </Span>
              </Fade>
            </Flex>
            <Spacer size={10} />
            <Fade in={hasSubmitError} exit={!hasSubmitError}>
              <P color={THEME.palette.error.main}>
                Ohhh rats. Something went wrong. Try again?
              </P>
            </Fade>
          </form>
        </CenteredMaxWidth>
      </Layout>
    );
  }
}

export default ContactPage;
