import styled from 'styled-components';
import mediaqueries from '../mediaqueries';

const H2 = styled.h2`
  font-size: 30px;
  font-weight: bold;
  color: #525252;
  letter-spacing: 1.2px;
  line-height: 1.5;

  ${mediaqueries.md`
    font-size: 35px;
  `}
`;

export default H2;
