import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  font-size: 16px;
  padding: 10px 10px;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  background-color: ${props => props.theme.palette.grey['50']};
  border-color: ${props => props.theme.palette.grey['700']};
  border-radius: 5px;
`;

const StyledTextArea = styled.textarea`
  font-size: 16px;
  padding: 10px 10px;
  border-width: 1px;
  border-style: solid;
  background-color: ${props => props.theme.palette.grey['50']};
  border-color: ${props => props.theme.palette.grey['700']};
  width: 100%;
  resize: none;
  height: 250px;
  border-radius: 5px;
`;

const LabelText = styled.div`
  padding-bottom: 5px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
`;

export const Input = ({
  label,
  id,
  type,
  onChange,
  value,
  placeholder,
  required = false,
}) => (
  <Label htmlFor={id}>
    <LabelText>{label}</LabelText>
    <StyledInput
      type={type || 'text'}
      id={id}
      name={id}
      onChange={onChange}
      placeholder={placeholder}
      required={required}
      value={value}
    />
  </Label>
);

export const TextArea = ({
  label,
  id,
  type,
  onChange,
  value,
  required = false,
}) => (
  <Label htmlFor={id}>
    <div>{label}</div>
    <StyledTextArea
      type={type || 'text'}
      id={id}
      name={id}
      onChange={onChange}
      required={required}
      value={value}
    />
  </Label>
);
